<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="医生姓名：">
				<el-input v-model="filters.doctorName" placeholder="医生姓名" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
      <el-table :data="dataList" border style="width: 100%;">
        <el-table-column prop="name" header-align="center" align="left" label="医生姓名"></el-table-column>
        <el-table-column prop="shouldOut" header-align="center" align="left" label="累计收入（元）">
          <template slot-scope="scope">
            {{scope.row.shouldOut}}
          </template>
        </el-table-column>
        <el-table-column prop="totalCashout" header-align="center" align="left" label="累计结算（元）">
          <template slot-scope="scope">
            {{scope.row.totalCashout}}
          </template>
        </el-table-column>
        <el-table-column prop="totalConsultation" header-align="center" align="left" label="总问诊费（元）"></el-table-column>
        <el-table-column prop="totalOutpatientCare" header-align="center" align="left" label="总诊费（元）"></el-table-column>
        <el-table-column prop="totalMntCosts" header-align="center" align="left" label="总处方管理费（元）"></el-table-column>
        <el-table-column prop="medicinePercentage" header-align="center" align="left" label="药品提成费（元）"></el-table-column>
        <el-table-column prop="operation" header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="detailHandle(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				doctorName: ''
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('cashOutDoctorList', {
				...this.filters,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						return r;
					});
				}
			});
		},
		detailHandle (item) {
			this.$root.go('/mnt/doctor-bill-detail', { id: item.id });
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>